import React, { useState, useEffect } from "react";
import "./Header.css";
import HamburgerIcon from "../../images/Hamurger.svg";
import ContactUsButton from "../../pages/contactus/contactusbutton";
import Offcanvas from "react-bootstrap/Offcanvas";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../../images/intointube-logo.png";

const contentLinks = [
  /*{
    link: 'stock-market',
    name: 'Stock Market'
  },*/
  {
    link: "books-in-summary",
    name: "Books In Summary",
  },
  {
    link: "zrti-qna",
    name: "ZRTI Q&A",
  },
];

function Header() {
  const location = window.location;
  const activeLink = location.pathname.split("/")[1];
  const [show, setShow] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSentencesDropDown, setShowSentencesDropDown] = useState(false);
  const [showElectricDropdown, setShowElectricDropdown] = useState(false);
  const [showDieselDropdown, setShowDieselDropdown] = useState(false);

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [location]);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const goToRoute = (path) => {
    window.location.href = path;
  };

  function renderAppDetailLinks() {
    return (
      <div className="content-categories">
        <div className="category">
          <a href={"/best-deals"}>Best Deals</a>
        </div>
        {/* <div className='category'>
          <Link to={'/best-products'}>Best Products</Link>
        </div>*/}
        <div className="category">
          <a href={"/calculators"}>Calculators</a>
        </div>
        <div className="category">
          <a href={"/content-investor-call"}>Content Investor Call</a>
        </div>
        <div className="category">
          <a href={"/contact-us"}>
            <ContactUsButton />
          </a>
        </div>
      </div>
    );
  }

  function renderAppContentLinks() {
    return (
      <>
        {contentLinks.map((content, index) => {
          return (
            <div
              className={`category ${
                content.link === activeLink ? "active" : ""
              }`}
              key={index}
            >
              <a href={`/${content.link}`}>{content.name}</a>
            </div>
          );
        })}
      </>
    );
  }

  function renderVocabularyMenu() {
    return (
      <div className="category">
        <DropdownButton
          align="end"
          title="Vocabulary"
          id="vocabulary-dropdown-btn"
          show={showDropDown}
          onMouseEnter={() => setShowDropDown(true)}
          onMouseLeave={() => setShowDropDown(false)}
        >
          <Dropdown.Item eventKey="1">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/vocabulary/beginner")}
            >
              Beginner
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/vocabulary/medium")}
            >
              Intermediate
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/vocabulary/advanced")}
            >
              Advanced
            </div>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  function renderSentencesMenu() {
    return (
      <div className="category">
        <DropdownButton
          align="end"
          title="Daily Sentences"
          id="sentences-dropdown-btn"
          show={showSentencesDropDown}
          onMouseEnter={() => setShowSentencesDropDown(true)}
          onMouseLeave={() => setShowSentencesDropDown(false)}
        >
          <Dropdown.Item eventKey="1">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/beginner_1")}
            >
              Beginner - Level 1
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/beginner_2")}
            >
              Beginner - Level 2
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/intermediate_1")}
            >
              Intermediate - Level 1
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="4">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/intermediate_2")}
            >
              Intermediate - Level 2
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="5">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/advanced_1")}
            >
              Advanced - Level 1
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="6">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/daily-sentences/advanced_2")}
            >
              Advanced - Level 2
            </div>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  function renderElectricTractionMenu() {
    return (
      <div className="category">
        <DropdownButton
          align="end"
          title="Electric Traction Q&A"
          id="electric-traction-dropdown-btn"
          show={showElectricDropdown}
          onMouseEnter={() => setShowElectricDropdown(true)}
          onMouseLeave={() => setShowElectricDropdown(false)}
        >
          <Dropdown.Item eventKey="1">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/electric-traction-qna")}
            >
              MCQs
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/blanks/electric-traction")}
            >
              Blanks
            </div>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  function renderDieselTractionMenu() {
    return (
      <div className="category">
        <DropdownButton
          align="end"
          title="Diesel Traction Q&A"
          id="diesel-traction-dropdown-btn"
          show={showDieselDropdown}
          onMouseEnter={() => setShowDieselDropdown(true)}
          onMouseLeave={() => setShowDieselDropdown(false)}
        >
          <Dropdown.Item eventKey="1">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/diesel-traction-qna")}
            >
              MCQs
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/blanks/diesel-traction")}
            >
              Blanks
            </div>
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <div
              className={`menu-item`}
              onClick={() => goToRoute("/blanks/diesel-traction/WDG4G")}
            >
              WDG4G Blanks
            </div>
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }

  return (
    <div
      className="header-container"
      style={{ background: "#000000", position: "sticky", top: 0, zIndex: 9 }}
    >
      <div className="header-block">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="d-none d-lg-block">{renderAppDetailLinks()}</div>
        <div
          className="d-block d-lg-none header-mobile logo-header"
          id="logo-header"
        >
          <div className="col-6 logo-section-mobile">
            <button
              className="navbar-toggler"
              onClick={toggleShow}
              type="button"
            >
              <img src={HamburgerIcon} alt={"Hamburger"} />
            </button>
          </div>
        </div>
      </div>
      <div className={`content-links d-none d-lg-flex`}>
        {renderVocabularyMenu()}
        {renderSentencesMenu()}
        {renderAppContentLinks()}
        {renderElectricTractionMenu()}
        {renderDieselTractionMenu()}
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="logo">
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </div>
          </Offcanvas.Title>
          <button className="offcanvas-btn-close" onClick={handleClose}>
            <i className="bi-x-lg" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="info-in-tube-detail-links">
            {renderAppDetailLinks()}
          </div>
          <div className="info-in-tube-content-links">
            <div className={`content-links d-block d-lg-none`}>
              {renderVocabularyMenu()}
              {renderSentencesMenu()}
              {renderAppContentLinks()}
              {renderElectricTractionMenu()}
              {renderDieselTractionMenu()}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Header;
