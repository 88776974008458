import React, { useEffect, useState } from 'react';

const urls = ['/', '/best-deals', '/content-investor-call', '/contact-us', '/about-us']

const AdDisplay = ({ adId, className='' }) => {
  const [ noAds, setAdEligible ] = useState(false);

  useEffect(() => {
    window.googletag.cmd.push(function() { 
      window.googletag.display(adId) 
    });

    const page = window.location.pathname;
    for(let i of urls) {
      if(i === page) {
        setAdEligible(true)
        break;
      }
    }

  }, []);

  return (
    <div className={ `${className} ${noAds ? 'd-none': '' }`} style={{ width: '100%', padding: '10px' }}>
      <div id={adId} style={{ textAlign: 'center' }} />
    </div>
    
  )
};

export default AdDisplay;