import React from 'react';
import './App.scss';
import { BrowserRouter as Router} from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/Footer/Footer";
import Routes from './routes';
import '../node_modules/alertifyjs/build/css/alertify.css';
import '../node_modules/alertifyjs/build/css/themes/semantic.css';

function App() {
  const page = window.location.pathname.split('/')[1]
  return (
    <div className="App"> 
    <Header />  
      <Router>
        {/* <AdDisplay adId={DESKTOP_HEADER_AD1} className='d-lg-block d-md-block d-none'/> */}
          <div className={`info-in-tube-body ${page !== '' ? page : 'home'}`}>
              <Routes  />
          </div>
        {/* <div className='footer-ad d-none d-md-block d-lg-block'>
          <AdDisplay adId={DESKTOP_FOOTER_STICKY_AD} />
        </div> */}
        
      </Router>
      <Footer />
    </div>

  );
}

export default App;
