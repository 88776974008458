import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { SQUARE_DESKTOP_MOBILE_AD1 } from "../../adconstants";
import { logPageViewEvent } from "../../analytics/analyticfunc";
import pageTitles from "../../analytics/pagetitles";
import ContentfulUtil from "../../client";
import metaData from "../../metadata";
import { sortMcqChapters } from "../../helpers/generichelper";
import SocialShare from "../../components/socialShare/socialShare";
import AdDisplay from "../../components/addisplay";
import ChapterTile from "../../components/chapter-tile-component/chapter-tile";
import LoadingComponent from "../../components/loadingcomponent";
import './zrtqna.scss';

const ZrtiQna = () => {
    const [chapters, setChapters] = useState(null);
    const metaZrti = metaData.getZrti();

    useEffect(() => {
        ContentfulUtil.getMcqContent('zrtiMcq')
        .then(response => {
            setChapters(sortMcqChapters(response.items, 'chapterTitle'))
        }).catch(console.error);
        logPageViewEvent({
            pageTitle: metaZrti.title,
            title: pageTitles.zrti.title
        })
    }, []);

    return (
        <div id='zrti-qna'>
            <MetaTags>
                <meta name='keywords' content={metaZrti.keywords.join()} />
                <meta name='description' content={metaZrti.description} />
            </MetaTags>
            <div className='title'>
                <h1>ZRTI Q&A</h1>
            </div>
            <p className="description">
                 ZRTI is Zonal Railway Training Institute which gives training for Guards, ALP, LP, Refreshers, Station Master etc., The Key areas focused on G&SR rules, Automatic block section, Absolute Block section, Signalling ,Alarm chain Pulling etc., This  Bit bank or MCQ Practice helps you make score High in ZRTI exams</p>
            <div style={{float: 'right'}}>
                <SocialShare />
            </div>
            <div className="heading">Multiple Choice Questions</div>
            <div style={{ margin: '10px 0' }}>
                <AdDisplay adId={SQUARE_DESKTOP_MOBILE_AD1} className={'d-sm-block d-md-none d-lg-none'}/>
            </div>
            <div className="chapters-list">
                {
                    chapters ? chapters.map((chapter, index) => {
                        return (
                            <ChapterTile 
                                url={'/zrti-mcq'}
                                index={index}
                                chapter_title={chapter.data.chapter_title}
                                total_questions={chapter.data.data.length}
                            />
                        )
                    }) : <LoadingComponent />
                }
            </div>
        </div>
    )
}

export default ZrtiQna;