import * as contentful from 'contentful';

class ContentfulUtil {
  static client = contentful.createClient({
    space: 'vtdu71he2gm2',
    accessToken: '_AG268bodT-GWh8iPFNvPkdA2SDp-kvElcTYD5Y7hF8'
  })


  static fetchStockMarketData = () => ContentfulUtil.client.getEntries({
    content_type: 'infointube',
    order: '-sys.createdAt'
  })

  static fetchStockData = (nseStockCode) => ContentfulUtil.client.getEntries({
    content_type: 'infointube',
    'fields.nseStockCode[in]': nseStockCode
  })

  static fetchAffiliateAds = () => ContentfulUtil.client.getEntries({
    content_type: 'affiliateads',
    order: '-sys.createdAt'
  })

  static fetchVocabularyByLetter = (letter) =>  ContentfulUtil.client.getEntries({
    content_type: 'vocabulary',
    'fields.letter': letter
  })

  static fetchIntermediateVocabularyByLetter = (letter) =>  ContentfulUtil.client.getEntries({
    content_type: 'vocabularyIntermediate',
    'fields.letter': letter
  })

  static fetchAdvancedVocabularyByLetter = (letter) =>  ContentfulUtil.client.getEntries({
    content_type: 'vocabularyAdvanced',
    'fields.letter': letter
  })

  static fetchBestProducts = () => ContentfulUtil.client.getEntries({
    content_type: 'bestproducts',
    order: '-sys.createdAt'
  })

  static fetchProductByName = (product) =>  ContentfulUtil.client.getEntries({
    content_type: 'bestproducts',
    'fields.itemTitle': product
  })

  static fetchTermsAndConditions = () => ContentfulUtil.client.getEntries({
    content_type: 'termsAndConditions',
    order: '-sys.createdAt'
  })

  static fetchPrivacyPolicy = () => ContentfulUtil.client.getEntries({
    content_type: 'privacyPolicy',
    order: '-sys.createdAt'
  })

  static getBooks = () => ContentfulUtil.client.getEntries({
    content_type: 'books',
    order: '-sys.createdAt'
  })

  static getRecommendBooks = () => ContentfulUtil.client.getEntries({
    content_type: 'books',
    order: '-sys.createdAt',
    limit: 5
  })

  static getBookByTitle= (title) =>  ContentfulUtil.client.getEntries({
    content_type: 'books',
    'fields.title': title
  })

  static getDailySentences = (level) => ContentfulUtil.client.getEntries({
    content_type: 'dailySentences',
    'fields.level': level
  })

  static getMcqContent = (content_type, num) => {
    const input = {
      content_type: content_type,
      order: '-sys.createdAt'
    }
    if(num) {
      input['fields.chapterTitle'] = 'chapter-'+num
    }
    return ContentfulUtil.client.getEntries(input)
  }

  static getBlanksContent = (topic) => ContentfulUtil.client.getEntries({
    content_type: topic,
    order: '-sys.createdAt'
  })

  static getBlogImages = () => ContentfulUtil.client.getEntries({
    content_type: 'blogImages',
    order: '-sys.createdAt'
  })

  static getWdg4gBlanks = () => ContentfulUtil.client.getEntries({
    content_type: 'wdg4gBlanks',
    order: '-sys.createdAt'
  })

}

export default ContentfulUtil;
