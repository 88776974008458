import {addLinkTagToHeader} from "../helpers/generichelper";

export const logPageViewEvent = ({locationUrl = window.location.href, title, pageTitle = 'InfoInTube Online Learning Platform'}) => {
  document.title = pageTitle
  addLinkTagToHeader();
  if(process.env.NODE_ENV === 'production') {
    window.ga('send', {
      hitType: 'pageview',
      page: locationUrl,
      title: title
    })
  }
};
