import React from 'react';
import loadingImg from '../images/loadingImg.gif';

const LoadingComponent = () => {
  return(
    <div style={{ textAlign: 'center', marginTop: '15%', width: '100%' }}>
      <img src={loadingImg} alt={'Loading...'} style={{ margin: 'auto' }} />
    </div>
  )
};

export default  LoadingComponent;