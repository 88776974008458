
const metaData = {
  home: {
    title: 'Vocabulary | Books summaries | Calculators | Loco pilot exams | Electric traction | Diesel Traction | InfoInTube Home',
    keywords: [ "infointube", "vocabulary", "calculators", "english vocabulary", "3000 vocabulary words", "languages", "all languages vocabulary", "all kind of calculators", "books", "book summary", "books in shorts", "personal growth books", "quiz", "quiz questions", "electric traction", "diesel traction", "question and answers"],
    description: 'InfoInTube provides valuable content to learn vocabulary and translations for indian languages,  Books in summaries and other competitive exams preparation practices'
  },
  todaysDeals: {
    title: "Today's Best Deals in InfoInTube",
    keywords: ['Today Deals', 'best deals', 'amazon products', 'ecommerce', 'best products', 'affiliating', 'best content', 'top deals', 'amazon deals', 'amazon products'],
    description: ['best deals in infointube', 'amazon todays deals in InfoIntube', 'best products in infointube']
  },
  // stockMarket: {
  //   title: 'Stock Market Fundamental Analysis in brief in InfoInTube',
  //   keywords: ['Stock market', ' share market', ' investor analysis', ' trader analysis',
  //     ' swing trading', ' positional trading', ' long term investments', ' bull market shares',
  //     ' bear market shares', ' annual report', ' fundamental analysis', ' technical analysis',
  //     ' know before invest', ' qualitative analysis', ' companies', ' shares', ' nse stocks',
  //     ' bse stocks', ' nifty', ' sensex', ' nifty50', ' nifty next50', 'nifty100', ' nifty200',
  //     ' nifty 500', ' nifty midcap', ' nifty small cap', ' penny stocks', ' sensex30',
  //     ' portfolio stocks', ' investment stocks', ' watchlist stocks', ' Blue chip stocks',
  //     ' peer companies', ' dividend stocks', ' multibagger stocks', ' index funds', ' ipo stocks',
  //     ' growth stocks', ' value stocks', ' large cap stocks', ' midcap stocks', ' smallcap stocks',
  //     ' sebi', ' best shares to buy', ' indian share market', ' equity investment', ' buy shares',
  //     ' how to buy shares', ' which share to buy', ' commodity market in india', ' investing in shares',
  //     ' derivative trading', ' currency trading in india', ' best shares to buy in india',
  //     ' best broker in india', ' commodity trading', ' derivatives market in india',
  //     ' investment companies', ' best shares to invest', ' best way to save money',
  //     ' trading platforms', ' buying shares in a company', ' demo trading account india',
  //     ' online currency trading in india', ' currency trading tips', ' best online trading platform in india',
  //     ' trading websites in india', ' indian broker', ' best online broker india', ' best company to buy shares',
  //     ' best broker for online trading in india', ' best trading account in india', 'equity portfolio management',
  //     ' trade advisor', ' best trading website india', ' top brokers', ' financial advisor companies',
  //     ' top 10 brokers in india', ' highest leverage stock broker in india', ' indian equity market',
  //     ' trading signals', ' currency market in india', ' equity portfolio', ' investment companies in india',
  //     '  best equity', ' intraday tips for tomorrow', ' bank nifty tips', ' option trading tips',
  //     ' stock tips for tomorrow', ' today intraday share', ' share market tips daily',
  //     ' commodity trading tips', ' nifty prediction', ' nifty call', ' intraday stocks for tomorrow',
  //     ' intraday stocks for today', ' mcx tips', ' commodity tips', ' share tips', ' share market trading',
  //     ' free share market tips', ' stock market tips for intraday free', ' stock tips', ' intraday stock tips',
  //     ' intraday tips for today', ' intraday calls', 'nifty tips', ' stock market tips', ' stock advice',
  //     ' best intraday tips', ' nifty option tips', ' trading tips', ' nse free tips', ' best intraday tips site',
  //     ' future option nifty', ' nifty call option', ' share tips daily', ' intraday stock tips for tomorrow',
  //     " today's intraday stocks tips", ' stocks to buy today for intraday', ' intraday shares',
  //     ' stocks to buy for intraday', ' tips for intraday trading in nse', ' stock recommendations for intraday',
  //     ' stock market tips today', ' free nifty tips', ' 100 accurate intraday tips', ' intraday tips for today nse',
  //     ' best intraday calls', ' free stock tips for tomorrow', ' trading tips for tomorrow', ' stock advice today',
  //     ' small cap stock recommendations', ' free nse intraday tips', ' stock market investment tips',
  //     ' stock future tips intraday', ' nse stock tips', ' free nifty option tips', ' best nifty tips',
  //     ' stock tips for tomorrow india', ' best advice for stock market', ' best stock tips india', ' online share tips',
  //     ' best stock tips site india', ' best free stock tips', ' share investment recommendations', ' bse stock market tips',
  //     ' stock buying tips today', ' nifty positional calls', ' equity stock market tips', ' stock market tips today free',
  //     ' best stock newsletters', ' best option tips provider', ' today share market tips india',
  //     ' indian stock market investment tips', ' stock market intraday trading tips', ' free stock tips india',
  //     ' stock trading tips for today', 'best shares for intraday trading tomorrow', ' stock future tips free', 'daily intraday calls',
  //     ' nse bse share tips'],
  //   description: ["Stock fundamental analysis before investing  InfoInTube",
  //                         "Stock annual report in short InfoInTube",
  //                         "Stock business model InfoInTube",
  //                         "Stock key highlights InfoInTube",
  //                         "Stock basic fundamental analysis InfoInTube",
  //                         "Stock analysis in InfoInTube",
  //                         "Stock basic details in InfoInTube",
  //                         "Stock business model in InfoInTube",
  //                         "Stock pros and cons in InfoInTube",
  //                         "Stock business understanding about company InfoInTube"]
  // },
  quiz: {
    title: 'Electric Traction Q&A in InfoInTube',
    keywords: ["Quiz", "competitive", "exams", "Government exams", "competitive exams", "loco pilot", "election traction questions"," eclectic traction",
                      "Railway exams", "central exams", "jobs", "railway jobs", "preparation","ETTC", "ELECTRIC TRACTION TRAINING CENTRE",
                      "Indian Railways", "Assistant Loco Pilots", "WAG5", " WAG7", " WAG9", " WAG9H", " WAG9HC" , " WAG 12",
                      "objective bits", "Practicing", "chapters", "InfoInTube"],
    description: 'Electric Traction is one of the subject from loco pilot preparation, this helps in learning to get the edge in Loco Pilot competitive'
  },
  vocabulary: {
    title: 'Learn vocabulary in InfoInTube',
    keywords: ['language', 'indian languages', 'interview vocabulary', 'gre exams', 'bank exams', 'translate words',
      'meanings', 'translate', 'words', 'vocabulary', ' vocab', ' words', ' word puzzles', ' word roots', ' English vocabulary', ' alphabets', ' alphabetical listing', ' language', ' languages learning', ' gmat', ' gre', ' lsat', ' tofel', ' ielts', ' gmat vocabulary', ' gre vocabulary', ' lsat vocabulary', ' tofel vocabulary', ' ielts vocabulary', ' learn hindi', ' learn bengali', ' learn telugu', ' learn tamil', ' learn urdu', ' learn malayalam', ' learn kannada', ' learn odia', ' learn marathi', ' learn punjabi', 'basic english', ' basic telugu', ' basic hindi', ' basic tamil', ' basic malayalam', ' basic kannada', ' basic odia', ' basic punjabi', ' vocabulary beginer', ' beginer vocabulary', 'tinglish', 'vocabulary practice', ' words meanings', ' telugu words', ' hindi words', ' malaylam words', ' odia words', ' tamil words', ' kannada words', ' punjabi words', ' urdu words', ' vocabulary translation', ' english to hindi translation', ' english to telugu translation', ' english to tamil translation', ' english to malayalam translation', ' english to urdu translation', ' english to kannada translation', ' english to punjabi translation', ' english to marathi translation', ' english to bengali translation', ' Vocabulary practice', ' english words pracice', ' dialy usage words'],
    description: 'InfoInTube have vocabulary content with top 12 indian languages and its translation in beginner, intermediate and advanced level with learn and practice mode'
  },
  dailySentences: {
    title: 'Daily Use Sentences',
    keywords: ['language', 'indian languages', 'interview vocabulary', 'gre exams', 'bank exams', 'translate words',
      'meanings', 'translate', 'words', 'vocabulary', ' vocab', ' words', ' word puzzles', ' word roots', ' English vocabulary', ' alphabets', ' alphabetical listing', ' language', ' languages learning', ' gmat', ' gre', ' lsat', ' tofel', ' ielts', ' gmat vocabulary', ' gre vocabulary', ' lsat vocabulary', ' tofel vocabulary', ' ielts vocabulary', ' learn hindi', ' learn bengali', ' learn telugu', ' learn tamil', ' learn urdu', ' learn malayalam', ' learn kannada', ' learn odia', ' learn marathi', ' learn punjabi', 'basic english', ' basic telugu', ' basic hindi', ' basic tamil', ' basic malayalam', ' basic kannada', ' basic odia', ' basic punjabi', ' vocabulary beginer', ' beginer vocabulary', 'tinglish', 'vocabulary practice', ' words meanings', ' telugu words', ' hindi words', ' malaylam words', ' odia words', ' tamil words', ' kannada words', ' punjabi words', ' urdu words', ' vocabulary translation', ' english to hindi translation', ' english to telugu translation', ' english to tamil translation', ' english to malayalam translation', ' english to urdu translation', ' english to kannada translation', ' english to punjabi translation', ' english to marathi translation', ' english to bengali translation', ' Vocabulary practice', ' english words pracice', ' dialy usage words', 'daily use sentences', 'regular sentences', 'normal communication'],
    description: 'InfoInTube have daily use sentences to learn to language to speak, read and understand, Its very useful and important to communicate with others.'
  },
  books: {
    title: 'Books In Summary | InfoInTube',
    keywords: [ "books", "books summary", "telugu books", "english books", "short books", "personal growth books", "z library", "ebook", "atomic habits", "think and grow rich", "the 7 habits of highly effective people", "the business school", "think and grow rich", "think Like a Monk", "Corporate Chanakya", "the power of your subconscious mind", "the power of subconscious mind", "The Magic of Thinking Big", "The Power of A Positive Attitude", "Chanakya Neeti", "The Science of Mind Management", "How to Win Friends and Influence People", "Compound Effect", "the secret", "Master Your Time and Master Your Life", "Get Smart", "Inner Engineering", "Ikigai", "Awareness", "reading books", "popular books", "amazon books", "personal growth books", "online book reading", "top books", "atomic habits book", "think and grow rich book", "the 7 habits of highly effective people book", "the business school book", "think and grow rich book", "think Like a Monk book", "Corporate Chanakya book", "The Power of Your Subconscious Mind book", "The Magic of Thinking Big book", "The Power of A Positive Attitude book", "Chanakya Neeti book", "The Science of Mind Management book", "How to Win Friends and Influence People book", "Compound Effect book", "the secret book", "Master Your Time and Master Your Life book", "Get Smart book", "Inner Engineering book", "Ikigai book", "Awareness book" ],
    description: 'InfoInTube has popular books in summaries which can finish 20 to 30 minutes for each book. Books summaries are in English & Telugu'
  },
  contentInvestor: {
    title: 'Content Investor Call in InfoInTube',
    keywords: ['content investor', 'create content and earn', 'earn money', 'earn money in online', 'passive income', 'financial freedom', 'write online blog', 'write blog and earn'],
    description: 'We are inviting the Content Investors, who are ready to come and join us. Individual people have knowledge in particular contents and they enjoy investigating the content. But when you share with the world, that makes you part of the people who are growing the world. There will be a lot of people who have content and they might not be knowing the platform where they can place and show to the world and It\'s not easy to create the platform. InfoInTube is the solution for you. It has created a solid platform to bring in your content InfoInTube analyzes your content, and designs the way users learn and develop the robust technology and automating the process. So whenever one creates content, everyday content will be onboard to the world. We from the InfoInTube team develop and promote in all social media regularly, your job is to just create the content and share it with us.'
  },
  contactUs: {
    title: 'Contact to InfoInTube'
  },
  aboutUs: {
    title: 'About Us InfoInTube'
  },
  bestProducts: {
    title: 'Best Products in InfoInTube',
    keywords: ['best deals', 'best products', 'top deals', 'amazon products', 'affiliate marketing', 'top products', 'amazon deals', 'best prices', 'low price products'],
    description: ['buy best products in amazon', 'best products from InfoInTube']
  },
  calculators: {
    title: 'Online Calculators',
    keywords: [ "calculator", "age calculator", "percentage calculator", "calc", "compound interest calculator", "loan calculator", "tax calculator", "car loan calculator", "emi calculator", "sip calculator", "salary calculator", "home loan calculator", "income tax calculator", "investment calculator", "interest calculator", "ppf calculator", "home loan emi calculator", "compound interest", "personal loan calculator", "mutual fund calculator", "tax calculator 2022", "simple interest calculator", "cagr calculator", "personal loan emi calculator", "stock calculator", "interest rate calculator", "hra calculator", "gratuity calculator", "sukanya samriddhi yojana calculator", "ssy calculator", "position sizing calculator" ],
    description: 'InfoInTube has all type of calculators, general basic calculator and all financial calculators',
    emi: {
      title: 'EMI Calculator for Home Loan, Car Loan & Personal Loan',
      keywords: [ "emi calculator", "home loan emi calculator", "car loan emi calculator", "personal loan emi calculator", "loan emi calculator", "car emi calculator", "home loan eligibility calculator", "emi calculator online", "housing loan emi calculator", "bike emi calculator", "loan eligibility calculator", "home emi calculator", "bike loan emi calculator", "how to calculate emi", "calculate monthly emi online", "calculate emi online" ],
      description: 'EMI Calculator - Calculate Equated Monthly Installment (EMI) for Home Loan / Housing Loan, Car Loan & Personal Loan in India'
    },
    'home-loan': {
      title: 'Home Loan Calculator online',
      keywords: [ "emi calculator", "home loan emi calculator", "car loan emi calculator", "personal loan emi calculator", "loan emi calculator", "car emi calculator", "home loan eligibility calculator", "emi calculator online", "housing loan emi calculator", "bike emi calculator", "loan eligibility calculator", "home emi calculator", "bike loan emi calculator", "how to calculate emi", "calculate monthly emi online", "calculate emi online" ],
      description: 'Home Loan Calculator - Calculate Equated Monthly Installment (EMI) for Home Loan / Housing Loan in India'
    },
    'personal-loan': {
      title: 'Personal Loan Calculator online',
      keywords: [ "emi calculator", "home loan emi calculator", "car loan emi calculator", "personal loan emi calculator", "loan emi calculator", "car emi calculator", "home loan eligibility calculator", "emi calculator online", "housing loan emi calculator", "bike emi calculator", "loan eligibility calculator", "home emi calculator", "bike loan emi calculator", "how to calculate emi", "calculate monthly emi online", "calculate emi online" ],
      description: 'Personal Loan Calculator - Calculate Equated Monthly Installment (EMI) for Home Personal Loan in India'
    },
    'car-loan': {
      title: 'Car Loan Calculator online',
      keywords: [ "emi calculator", "home loan emi calculator", "car loan emi calculator", "personal loan emi calculator", "loan emi calculator", "car emi calculator", "home loan eligibility calculator", "emi calculator online", "housing loan emi calculator", "bike emi calculator", "loan eligibility calculator", "home emi calculator", "bike loan emi calculator", "how to calculate emi", "calculate monthly emi online", "calculate emi online" ],
      description: 'Car Loan Calculator - Calculate Equated Monthly Installment (EMI) for Car Loan in India'
    },
    sip: {
      title: 'SIP Calculator - Systematic Investment Plan Calculator Online',
      keywords: [ "sip calculator", "mutual fund calculator", "lumpsum calculator", "sip return calculator", "mutual fund sip calculator", "mutual fund return calculator", "lump sum sip calculator", "mf calculator", "elss calculator", "sip interest rate", "sip lumpsum calculator", "mutual fund lumpsum calculator", "mutual fund calculator sbi", "sip calculator online", "sip investment calculator", "sip calculator finology", "yearly sip calculator", "mf return calculator", "lumpsum mutual fund calculator", "sip cagr calculator", "mutual fund calculator lumpsum", "monthly sip calculator", "sip interest calculator", "lumpsum investment calculator", "sip maturity calculator", "lumpsum investment", "mutual fund sip return calculator", "sip goal calculator", "sip returns", "sip calculator with top up", "mutual funds investment calculator", "lumpsum calculator finology" ],
      description: 'SIP Calculator - Systematic Investment Plan calculator is a tool that helps you determine the returns you can avail when parking your funds in such investment tools.'
    },
    lumpsum: {
      title: 'Lumpsum Calculator - Lumpsum Investment Plan Calculator Online',
      keywords: [ "lump sum sip calculator", "sip lumpsum calculator", "mutual fund lumpsum calculator", "lumpsum mutual fund calculator", "mutual fund calculator lumpsum", "lumpsum investment calculator", "lumpsum investment", "lumpsum calculator finology", "lump sum investment calculator", "lumpsum return calculator", "lump sum calculator", "lumpsum calculator", "lumpsum calculator cagr", "sip calculator lumpsum", "finology lumpsum calculator", "lumpsum plus sip calculator", "cagr calculator lumpsum", "mf lumpsum calculator", "sip and lumpsum calculator", "cagr lumpsum calculator" ],
      description: 'Lumpsum Calculator - Calculate Returns in Online Lumpsum Calculator and make the best plan to achieve your financial goals.'
    },
    cagr: {
      title: 'CAGR Calculator: Calculate Compound Annual Growth Rate Online',
      keywords: [ "cagr calculator", "cagr formula", "cagr calculator online", "reverse cagr calculator", "compound annual growth rate calculator", "annual growth rate formula", "annual growth rate calculator", "compound annual growth rate formula", "cagr return calculator", "compound growth rate", "cagr rate", "cagr growth calculator", "cagr", "compound annual growth rate", "calculate cagr online", "how to caluculate cagr" ],
      description: 'CAGR Calculator - Calculate the compound annual growth rate online for your investment over a time period.'
    },
    'compound-interest': {
      title: 'Compound Interest Calculator Online India',
      keywords: [ "compound interest calculator", "compound interest formula", "compound interest", "compound calculator", "daily compound interest calculator", "ci formula", "compound interest formula calculator", "simple interest and compound interest", "compound interest formula example", "simple and compound interest", "power of compounding calculator", "monthly compound interest calculator", "cumulative interest calculator", "compound interest calculator excel", "compound interest rate calculator", "compound formula", "compound interest and simple interest formula", "compound daily calculator", "calculate compound interest online", "compound interest rate formula", "monthly compound interest formula", "simple and compound interest formula" ],
      description: 'Compound interest calculator is online tool to calculate compounding interest over the selected interval'
    },
    hra: {
      title: 'HRA Calculator Online',
      keywords: [ "hra calculator", "hra exemption calculator", "hra calculation formula", "hra calculator online", "hra calculation for income tax", "hra deduction calculation", "hra tax exemption calculator", "hra formula", "hra calculation in excel", "hra calculation in salary", "house rent allowance calculation", "hra rebate calculation", "hra calculator income tax", "hra calculation for tax", "hra exemption formula", "hra computation", "hra allowance calculation", "80gg calculator", "hra calculation for itr", "house rent exemption calculator", "house rent calculation for income tax", "hra is calculated on", "hra calculation formula for income tax", "house rent tax calculation", "hra benefit calculator", "hra exemption calculator online", "salary for hra", "tax on hra calculation", "hra tax benefit calculator", "income tax hra exemption calculator", "hra calculation with example" ],
      description: 'HRA Calculator is online tool to calculate the total amount the exempted for the tax saving in one financial year'
    },
    gratuity: {
      title: 'Gratuity Calculator Online',
      keywords: [ "gratuity calculator", "gratuity formula", "gratuity rules", "gratuity calculation formula", "gratuity calculator formula 2022", "gratuity calculator online", "gratuity calculation formula 2021", "gratuity calculation chart", "gratuity amount", "calculation of pension commutation and gratuity of central govt employees", "gratuity calculator for central government employees", "dcrg calculation", "gratuity calculation formula 2020", "gratuity formula 2021", "gratuity payment rules", "new gratuity rules", "gratuity amount calculation", "the formula for calculating gratuity is", "employee gratuity calculator", "retirement gratuity calculation formula", "calculation of gratuity on retirement", "gratitude calculation", "gratuity tax calculator", "central government gratuity calculator", "new gratuity calculation formula", "gratuity calculation for private company", "gratuity eligibility calculator", "gratuity calculator for private employees" ],
      description: 'Gratuity Calculator is online tool to compute Gratuity for salaried people whose working tenure is greater than 5 years in one company'
    },
    tax: {
      title: 'Tax Calculator online | Monthly salary calculation',
      keywords: [ "tax calculator", "salary calculator", "take home calculator", "monthly take home calculator", "income tax calculator", "pay calculator", "take home pay calculator", "tax calculator 2021", "tax return calculator", "paye calculator", "wage calculator", "capital gains tax calculator", "take home salary calculator", "take home pay", "net pay calculator", "income calculator", "tax calculator 2022", "2022 tax calculator", "salary tax calculator", "tax estimator", "salary after tax", "net salary calculator", "net income calculator", "incometax calculator", "simple tax calculator", "net salary", "monthly salary calculator", "gross income calculator", "salary check", "take home salary", "taxable income calculator", "tax deduction calculator", "tds interest calculator", "online tax calculator", "new tax regime calculator", "salary converter", "pay tax calculator", "old regime calculator", "how to calculate tax" ],
      description: 'Tax or salary calculator is online tool which gives your tax calculation based on your income and exemptions for both old and new regimes.'
    },
    ppf: {
      title: 'PPF Calculator online | Calculate PPF online',
      keywords: [ "ppf calculator", "ppf interest rate", "ppf account interest rate", "ppf account calculator", "ppf interest calculator", "ppf interest rate calculator", "ppf calculator monthly", "ppf return calculator", "interest on ppf", "ppf calculator excel", "ppf maturity calculator", "ppf calculator 2022", "ppf calculator 2021", "interest on ppf account", "public provident fund calculator", "ppf calculator online" ],
      description: 'PPF calculator is online tool which calculates maturity value which can invest in any bank for minimum of 15 Years'
    },
    ssy: {
      title: 'Sukanya Samriddhi Yojana calculator online',
      keywords: [ "ssy calculator", "sukanya samriddhi yojana calculator online", "sukanya samriddhi yojana calculator", "sukanya yojana calculator", "sukanya samriddhi calculator", "sukanya calculator", "sukanya samriddhi account calculator", "sukanya samriddhi yojana calculator 2021", "post office sukanya samriddhi account calculator", "sukanya samriddhi yojana chart", "sbi sukanya samriddhi yojana calculator", "ssy calculator 2022", "sukanya samriddhi scheme calculator", "ssy calculator post office 2021", "post office sukanya samriddhi yojana calculator", "calculation monthly sukanya samriddhi yojana calculator", "monthly sukanya samriddhi yojana calculator" ],
      description: 'PPF calculator is online tool for the investment of your girls child which calculates maturity value which can invest in any bank or post office for minimum of 15 Years'
    },
    'position-sizing': {
      title: 'Position size calculator online',
      keywords: [ "trade calculator", "stock trade calculator", "stock profit calculator", "share profit calculator", "call option profit calculator", "stock margin calculator", "position size calculator stocks", "stock market profit calculator", "intraday trading margin", "share market profit calculator", "stock trading position sizing calculator", "stock price profit calculator", "stock trading position sizing calculator excel" ],
      description: 'Position size calculator is online tool to calculate how many quantity can take for trade to follow strict risk reward ratio'
    }
  },
  dieselTraction: {
    title: 'Diesel Traction | InfoInTube',
    keywords: ["Quiz", "competitive", "exams", "Government exams", "competitive exams", "loco pilot", "diesel traction questions"," diesel traction",
      "Railway exams", "central exams", "jobs", "railway jobs", "preparation","ETTC", "diesel TRACTION TRAINING CENTRE",
      "Indian Railways", "Assistant Loco Pilots", "WAG5", " WAG7", " WAG9", " WAG9H", " WAG9HC" , " WAG 12",
      "objective bits", "Practicing", "chapters", "InfoInTube"],
    description: 'Diesel Traction is one of the subject from loco pilot preparation, this helps in learning to get the edge in Loco Pilot competitive'
  },
  zrti: {
    title: 'ZRTI Questions | InfoInTube',
    keywords: ["ZRTI", "Quiz", "competitive", "PCOM", "GM", "CTM", "PCSO", "SWR", "WTT", "Railway Safety", "exams", "Government exams", "competitive exams", "loco pilot", "diesel traction questions"," diesel traction",
      "Railway exams", "central exams", "jobs", "railway jobs", "preparation","ETTC", "diesel TRACTION TRAINING CENTRE",
      "Indian Railways", "Assistant Loco Pilots", "WAG5", " WAG7", " WAG9", " WAG9H", " WAG9HC" , " WAG 12",
      "objective bits", "Practicing", "chapters", "InfoInTube"],
    description: 'ZRTI is Zonal Railway Training Institute which gives training for Guards, ALP, LP, Refreshers, Station Master etc., The Key areas focused on G&SR rules, Automatic block section, Absolute Block section, Signalling ,Alarm chain Pulling etc., This  Bit bank or MCQ Practice helps you make score High in ZRTI exams'
  }
};

metaData.getHome = () => {
  return metaData.home
};

metaData.getTodayDeals = () => {
  return metaData.todaysDeals
};

metaData.getStockMarket = () => {
  return metaData.stockMarket
};

metaData.getQuiz = () => {
  return metaData.quiz;
};

metaData.getVocabulary = () => {
  return metaData.vocabulary;
};

metaData.getContentInvestor = () => {
  return metaData.contentInvestor;
};

metaData.getContact = () => {
  return metaData.contactUs;
};

metaData.getAboutUs = () => {
  return metaData.aboutUs;
};

metaData.getBestProducts = () => {
  return metaData.bestProducts;
};

metaData.getCalculators = () => {
  return metaData.calculators;
};

metaData.getDieselTraction = () => {
  return metaData.dieselTraction;
};

metaData.getBooks = () => {
  return metaData.books
};

metaData.getDailySentences = () => {
  return metaData.dailySentences;
};

metaData.getZrti = () => {
  return metaData.zrti;
}

export default metaData