import React from "react";
import './Footer.css';

function Footer () {
  return(
    <div className="footer">
      <div className="footer-section-2">
        <div>
          <span>We are always ready to hear your concern or feedback, write to </span>
          <span className="contact-email">
          <a href="mailto:infointube@gmail.com">
            <i className={'bi-envelope-fill'} style={{ fontSize: '14px' }}/> infointube@gmail.com
          </a>
        </span>
        </div>

        <div className="social-apps">
          <div className="social-heading">
            We are in
          </div>
          <div className="social-icons">
          <a href="https://www.facebook.com/infointube/" className="facebook" target="_blank" rel="noreferrer">
            <i className="fa fa-facebook" />
          </a>
          <a href="https://www.instagram.com/infointube/" className="insta" target="_blank" rel="noreferrer">
            <i className="fa fa-instagram"  />
          </a>
          <a href="https://www.pinterest.com/infointube/" className="pinterest" target="_blank" rel="noreferrer">
            <i className="fa fa-pinterest" />
          </a>
            <a href="https://www.linkedin.com/in/info-intube-50809322b/" className="linkedin" target="_blank" rel="noreferrer">
              <i className="fa fa-linkedin-square" />
            </a>
            <a href="https://www.youtube.com/channel/UCsNOL8Kz32u08rgy9FE-XUQ" className="youtube" target="_blank" rel="noreferrer">
              <i className="fa fa-youtube" />
            </a>
          </div>
        </div>
      </div>
      <div className='site-norms'>
        InfoInTube is optimized for learning, testing, and training. Examples might be simplified to
        improve reading and basic understanding. Tutorials, references, and examples are constantly
        reviewed to avoid errors, but we cannot warrant full correctness of all content.
        While using this site, you agree to have read and accepted our <a href={'/terms-and-conditions'}>terms and conditions</a> and <a href={'/privacy-policy'}>privacy policy.</a>
      </div>
      <div className="rights">
        ©2022 InfoInTube. All rights reserved - <a href={'/about-us'}>About Us</a>
      </div>
    </div>
  )
}

export default Footer;
