 const pageTitles = {
  home: {
    title: 'Home'
  },
   todaysDeals: {
    title: 'Today Deals Home'
   },
  stockMarket: {
    title: 'Stock Market Home',
    subtitle: 'Stock Market Company Detail'
  },
  electricTraction: {
    title: 'Electric Traction Home',
    subtitle: 'Electric Traction Chapter'
  },
  vocabulary: {
    title: 'Vocabulary Home'
  },
  dailySentences: {
    title: 'Daily Sentences'
  },
   books: {
    title: 'Books In Shorts'
   },
  contentInvestor: {
    title: 'Content Investor Home'
  },
  contactUs: {
    title: 'Contact Us'
  },
   bestProducts: {
    title: 'Best Products Home',
    subtitle: 'Best Products Detail'
   },
   calculators: {
    title: 'calculators'
   },
   dieselTraction: {
    title: 'Diesel Traction Questions Home'
   },
   zrti: {
    title: 'ZRTI Questions'
   }
};

export default pageTitles;