export default function NumberWithCommas(value) {
  let result = value.toString().split('.')[0].length > 3 ? value.toString().substring(0,value.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + value.toString().substring(value.toString().split('.')[0].length-3): value.toString();
  return ' ' + result.split(',').join(', ')
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function copySelected(text, callback) {
  if(text) {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    const ok = document.execCommand('copy');
    if(ok) {
      callback();
    }
    document.body.removeChild(elem);
  }
}

export function GetQueryParams(search) {
  return new URLSearchParams(search);
}

export const addLinkTagToHeader = ()=> {
  let linkTag = document.createElement('link')
  linkTag.setAttribute('rel', 'canonical');
  linkTag.setAttribute('href', window.location.href);
  const head = document.querySelector('head')
  head.append(linkTag)
}

export const sortMcqChapters = (items, chapterTitle) => {
  items = items.map((item) => {
    return item.fields
  })
  return (items.sort((a, b) => a[chapterTitle].localeCompare(b[chapterTitle])))
}