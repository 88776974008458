import React, { Suspense, lazy, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
/*import BestProductsHome from './components/bestproducts/bestproductshome';
import ProductDetail from "./components/bestproducts/productdetail";
import StockMarketHome from "./components/StockMarket/stockmarkethome";
import StockDetailView from "./components/StockMarket/stockdetail";*/

import AdDisplay from "./components/addisplay";
import LoadingComponent from './components/loadingcomponent';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

import {  
  CUBE_DESKTOP_AD,
  DESKTOP_FOOTER_AD1, 
  DESKTOP_RIGHT_BLOCK_AD1,
 // DESKTOP_RIGHT_BLOCK_AD2,
  SQUARE_DESKTOP_MOBILE_AD1
 } from './adconstants';

import ZrtiQna from './pages/zrtiqna/zrtiqna';
//import HomePage from './pages/home/home';
const HomePage = lazy(() => import('./pages/home/home'))
const Affiliateads = lazy(() => import('./pages/affiliateads/affiliateads'));
const DailySentences = lazy(() => import('./pages/dailysentences/dailysentences'));
const ElectricTractionQnA = lazy(() => import("./pages/ElectricTractionQnA/electrictractionqna"));
const QuestionsList = lazy(() => import("./pages/ElectricTractionQnA/questionslist"));
const Vocabulary = lazy(() => import('./pages/vocabulary/vocabulary'));
const Calculators = lazy(() => import('./pages/calculators/calculatorspage'));
const EmiCalculator = lazy(() => import('./pages/calculators/emicalculator/emicalculator'));
const SipLumpsumCalculator = lazy(() => import('./pages/calculators/siplumpsum/siplumpsum'));
const CagrCalculator = lazy(() => import('./pages/calculators/cagrcalculator/cagrcalculator'));
const ContentInvestorCall = lazy(() => import('./pages/contentinvestorcall/contentinvestorcall'));
const ContactUsPage = lazy(() => import('./pages/contactus/contactuspage'));
const DieselTraction = lazy(() => import('./pages/dieseltractionqna/dieseltractionqna'));
const TermsAndConditions = lazy(() => import("./pages/sitenorms/termsandconditions"));
const PrivacyPolicy = lazy(() => import("./pages/sitenorms/privacypolicy"));
const CompoundInterest = lazy(() => import("./pages/calculators/compoundinterest/compoundinterest"));

const BooksHome = lazy(() => import("./pages/books/bookshome"));
const BooksReadPage = lazy(() => import("./pages/books/booksreadpage"));
const HraCalculator = lazy(() => import('./pages/calculators/hraCalculator/hraCalculator'));
const Taxcalculator = lazy(() => import('./pages/calculators/taxcalculator/taxcalculator'));
const GratuityCalculator = lazy(() => import("./pages/calculators/gratuitycalculator/gratuitycalculator"));
const PpfCalculator = lazy(() => import("./pages/calculators/ppfcalculator/ppfcalcualtor"));
const SsyCalculator = lazy(() => import("./pages/calculators/ssycalculator/ssycalcualtor"));
const PositionSizing = lazy(() => import("./pages/calculators/positionsizing/positionsizing"));
const DieselTractionBlanks = lazy(() => import("./pages/dieseltractionqna/dieseltractionblanks"));
const Wdg4gBlanks = lazy(() => import("./pages/dieseltractionqna/wdg4gblanks"));
const AboutUs = lazy(() => import("./pages/aboutus/aboutus"));

const Routes = () => {
  let [showScrollTop, setShowScrollTop] = useState(false);

  window.addEventListener('scroll', () => {
    if (window.innerHeight < window.scrollY) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  });

  const goToTop = () => {
    window.location.href = window.location.pathname;
  };

  return (
    <div className={`info-in-tube-pages`}>
      {showScrollTop ? (<div className='scroll_to_top' onClick={goToTop} style={{    zIndex: 1, cursor: 'pointer', position: 'fixed', bottom: '25px', right: '50px' }}>
        <i className='bi-arrow-up-square-fill' style={{ fontSize: '35px',color: '#009968'}} />
      </div>) : null}
      <div className='google-left-ads d-none d-lg-block'>
        <div className='stick-ads'>
          <AdDisplay adId={SQUARE_DESKTOP_MOBILE_AD1}/>
          <div style={{ height: '20px' }} />
          <AdDisplay adId={CUBE_DESKTOP_AD} />
        </div>
      </div>
      <div className='content'>
        <Suspense fallback={<LoadingComponent />}>
            <Switch>
              <Route path='/' exact component={HomePage} />
              <Route path='/best-deals' exact component={Affiliateads} />
              {/* <Route path='/best-products' exact component={BestProductsHome} />
              <Route path='/best-products/:product_name' component={ProductDetail} />
              <Route path='/stock-market' exact component={StockMarketHome} />
              <Route path="/stock-market/:stock" component={StockDetailView} />*/}
               <Route path='/daily-sentences/:level' component={DailySentences}/>
              <Route path='/books-in-summary' exact component={BooksHome}/>
              <Route path='/books-in-summary/:bookTitle/:pageNum?' component={BooksReadPage}/>
              <Route path='/electric-traction-qna' exact component={ElectricTractionQnA}/>
              <Route path='/electric-traction-mcq' component={QuestionsList}/>
              <Route path='/diesel-traction-mcq' component={QuestionsList}/>
              <Route path='/blanks/:topic' exact component={DieselTractionBlanks}/>
              <Route path='/blanks/diesel-traction/WDG4G' exact component={Wdg4gBlanks}/>
              <Route path='/zrti-qna' exact component={ZrtiQna} />
              <Route path='/zrti-mcq' component={QuestionsList}/>
              <Route path='/vocabulary/:category/:letter?' component={Vocabulary}/>
              <Route path='/diesel-traction-qna' exact component={DieselTraction}/>
              <Route path='/blanks/:topic' exact component={DieselTractionBlanks}/>
              <Route path='/calculators' exact component={Calculators} />
              <Route path='/calculators/tax' exact component={Taxcalculator} />
              <Route path='/calculators/hra' exact component={HraCalculator} />
              <Route path='/calculators/emi' exact component={EmiCalculator} />
              <Route path='/calculators/home-loan' exact component={EmiCalculator} />
              <Route path='/calculators/car-loan' exact component={EmiCalculator} />
              <Route path='/calculators/personal-loan' exact component={EmiCalculator} />
              <Route path='/calculators/cagr' exact component={CagrCalculator} />
              <Route path='/calculators/compound-interest' exact component={CompoundInterest} />
              <Route path='/calculators/gratuity' exact component={GratuityCalculator} />
              <Route path='/calculators/ppf' exact component={PpfCalculator} />
              <Route path='/calculators/ssy' exact component={SsyCalculator} />
              <Route path='/calculators/position-sizing' exact component={PositionSizing} />
              <Route path='/calculators/:investmentType' exact component={SipLumpsumCalculator} />
              <Route path='/content-investor-call' component={ContentInvestorCall} />
              <Route path='/contact-us' component={ContactUsPage} />
              <Route path='/terms-and-conditions' component={TermsAndConditions} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route path='/about-us' component={AboutUs}/>
              <Redirect from='*'  to='/' /> 
            </Switch>
        </Suspense>
        <AdDisplay adId={DESKTOP_FOOTER_AD1} className='d-lg-block d-none' />
      </div>
      <div className='google-right-ads d-none d-md-block d-lg-block'>
        <AdDisplay adId={DESKTOP_RIGHT_BLOCK_AD1} className='stick-ads' />
        {/* <div style={{ height: '20px' }} />
        <AdDisplay adId={DESKTOP_RIGHT_BLOCK_AD2} /> */}
      </div>
    </div>
  )
};

export default Routes;