import React, { useEffect, useState } from 'react';
import {copySelected} from "../../helpers/generichelper";
import './socialShare.css';

function SocialShare () {
  const [locationUrl, setLocationUrl] = useState('');
  const [copyIcon, setCopyIcon] = useState('clone')

  useEffect(()=> {
    setLocationUrl(encodeURIComponent(window.location.href))
  }, []);

  const copyUrl = () => {
    copySelected(window.location.href, () => {
      setCopyIcon('check-square');
    });
  };

  return (
    <div className='social-share'>
      <span className='text'> Share in: &nbsp;</span>
      <a href={`https://api.whatsapp.com/send?text=${locationUrl}`} aria-label={'whatsapp'} data-action="share/whatsapp/share" target="_blank" className="whatsapp" rel="noreferrer"><i className="fa fa-whatsapp" /></a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${locationUrl}`} aria-label={'facebook'} target="_blank" className="facebook" rel="noreferrer"><i className="fa fa-facebook-square" /></a>
      <a href={`https://twitter.com/intent/tweet?text=${locationUrl}`} aria-label={'twitter'} className="twitter" target="_blank" rel='noreferrer'><i className="fa fa-twitter" /></a>
      <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${locationUrl}`} aria-label={'linkedin'} className="linkedin" target="_blank" rel='noreferrer'><i className="fa fa-linkedin-square" /></a>
      <span onClick={copyUrl} className="local-copy" aria-label={'local-copy'} target="_blank" rel='noreferrer'><i className={`fa fa-${copyIcon}`} /></span>
    </div>
  )
}

export default SocialShare;