import React from 'react';
import './chapter-tile.scss';

const ChapterTile = ({ url, index, chapter_title, total_questions}) => {
    return (
        <React.Fragment key={index}>
            <a href={`${url}?chapter=${index+1}`} key={index} className='chapter-tile'>
            <div className='chapter'>
                <div className='chapter-title'>{`Chapter ${index + 1}`}</div>
                <div className='text'>{`Title: ${chapter_title}`}</div>
                <div className='text'>{`Total Questions: ${total_questions}`}</div>
            </div>
            </a>
        </React.Fragment>
    )
};

export default ChapterTile;